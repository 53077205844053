<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link to="/" class="navbar-brand m-2">
            <img src="@/assets/applogo.png" alt="Logo" class="me-2 ms-2 logo" width="40" height="40">
            <b> JO JO Videos </b>
        </router-link>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul class="navbar-nav">
                <!-- <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About</router-link>
            </li> -->
            </ul>
        </div>

        <div class="m-3">
            <button class="btn btn-outline-danger" @click="logout">Logout</button>
        </div>
    </nav>
</template>
  
<style>
.logo {
    border-radius: 50%;
}
</style>
  
<script>

export default {
    data() {
        return {

        };
    },
    name: 'NavigationBar',
    components: {
    },
    methods: {
        logout() {
            // Remove the authentication key from sessionStorage
            sessionStorage.removeItem('authKey');
            // Set isAuthenticated to false
            this.isAuthenticated = false;
            // Redirect to the login page or any other route
            this.$router.push('/');
        },
    }
}
</script>
  
  