class UploadHelper {
  constructor() {
    this.hashtags = [];
    this.description = "";
    this.formData = new FormData();
  }
  constructFormData(videoFile, videoData, user_id) {
    // Get Hashtags
    this.constructHashtags(videoData);

    // Construct Description
    this.constructDescription(videoData);

    // Create the Form Data
    this.formData.append("video", videoFile);
    this.formData.append("user_id", user_id);
    this.formData.append("hashtags_json", this.hashtags);
    this.formData.append("video_id", 0);
    this.formData.append("privacy_type", "Public");
    this.formData.append("sound_id", 0);
    this.formData.append("allow_duet", 1);
    this.formData.append("description", this.description);
    this.formData.append("allow_comments", true);
    this.formData.append("users_json", []);
    this.formData.append("videoType", 0);
    this.formData.append("story", 0);
    this.formData.append("old_video_id", videoFile["name"]);

    return this.formData;
  }

  constructDescription(videoData) {
    this.description = videoData["description"];
    // Means hashtags are present
    if (this.hashtags.length > 0) {
      for (let i = 0; i < this.hashtags.length; i++) {
        this.description += " #" + this.hashtags[i]["name"];
      }
    }
  }

  constructHashtags(videoData) {
    let hashtagsString = videoData["hashtags"];
    let hashtagsArray = hashtagsString.split(";").map((tag) => tag.trim());

    if (hashtagsArray.length > 0) {
      const hashtags_json = hashtagsArray.map((item) => ({ name: item }));
      this.hashtags = hashtags_json;
    }
  }
}

export default UploadHelper;
