import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
    meta: { isLoginPage: true, requiresAuth: false },
  },
  {
    path: "/upload-bulk",
    name: "upload-bulk",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Navigation guard to check for authentication
router.beforeEach((to, from, next) => {
  const authKey = sessionStorage.getItem("authKey");

  // Check if the route requires authentication and if the authKey is not present
  if (to.meta.requiresAuth && !authKey) {
    // Redirect to the home page or any other route you prefer
    next("/");
  } else if (
    to.meta.requiresAuth == false &&
    to.meta.isLoginPage == true &&
    authKey
  ) {
    next("/upload-bulk");
  } else {
    // Continue with the navigation
    next();
  }
});

export default router;
