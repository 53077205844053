import axios from "axios";

class ApiService {
  constructor() {
    this.baseURL = "https://jojovideos.com/v3/api";
    this.apiKey = "186c8645-4658-1594-2202-91580";
  }

  async getUserIDByEmail(user_email) {
    const url = `${this.baseURL}/getUserFromEmail?email=${encodeURIComponent(
      user_email
    )}`;

    const response = await this.fetchData(url);
    // Means the User ID Exists
    if (response["code"] == 200) {
      const res = {
        id: response["data"]["User"]["id"],
      };
      return res;
    } else {
      return null;
    }
  }

  async getUserIDByUsername(username) {
    const url = `${
      this.baseURL
    }/getUserFromUsername?username=${encodeURIComponent(username)}`;

    const response = await this.fetchData(url);
    // Means the User ID Exists
    if (response["code"] == 200) {
      const res = {
        id: response["data"]["User"]["id"],
      };
      return res;
    } else {
      return null;
    }
  }

  async adminLogin(email, password) {
    const url = `${this.baseURL}/verifyAdminDetails`;
    const dataToSend = {
      admin_email: email,
      password: password,
    };

    try {
      const response = await axios.post(url, dataToSend, {
        headers: {
          "Content-Type": "application/json",
          "Api-Key": this.apiKey,
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async fetchData(url) {
    const headers = {
      "Api-Key": "186c8645-4658-1594-2202-91580",
      "Content-Type": "application/json", // Example of a content-type header
    };
    try {
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default ApiService;
