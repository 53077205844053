<template>
    <div class="container mt-4" style="max-width: 600px;">
        <!-- File Upload Section -->
        <div class="mb-3">
            <label for="fileInput" class="form-label">Choose Videos to Upload:</label>
            <input type="file" id="fileInput" ref="fileInput" multiple @change="handleFileUpload" class="form-control">

        </div>

        <!-- CSV Upload Section -->
        <div class="mb-3">
            <label for="csvInput" class="form-label">Choose CSV File:</label>
            <input type="file" id="csvInput" @change="handleCSVUpload" accept=".csv" class="form-control">
        </div>

        <button @click="uploadFiles" class="btn btn-primary mt-2" :disabled="!isButtonClickable"> {{ buttonText }} </button>

        <!-- Console Messages Section -->
        <div v-if="consoleMessages.length > 0">
            <h2 class="mt-4">Successful Uploads</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th>Video ID</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(message, index) in consoleMessages" :key="index">
                        <td>{{ message.video_id }}</td>
                        <td>{{ message.status }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="failureUploads.length > 0">
            <h2 class="mt-4">Failure Uploads</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th>Video ID</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(message, index) in failureUploads" :key="index">
                        <td>{{ message.video_id }}</td>
                        <td>{{ message.status }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

  
<script>
import axios from 'axios';
import UploadHelper from '../utils/UploadHelper.js'
import ApiService from '../utils/ApiService.js'
import Swal from 'sweetalert2'


export default {
    methods: {
        handleCSVUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.readCSV(file);
            }
        },

        readCSV(file) {
            const reader = new FileReader();
            reader.onload = event => {
                const csvData = [];
                const lines = event.target.result.split('\n');
                const headers = lines[0].split(',');

                for (let i = 1; i < lines.length; i++) {
                    const data = lines[i].split(',');
                    if (data.length === headers.length) {
                        const obj = {};
                        for (let j = 0; j < headers.length; j++) {
                            if (headers[j] == "hashtags") {
                                console.log(headers[j])
                            }

                            obj[headers[j].trim()] = data[j].trim();
                        }
                        csvData.push(obj);
                    }
                }

                this.csvData = csvData;
            };
            reader.readAsText(file);
        },

        processCSV() {
            // Perform any further processing or actions with the parsed CSV data
            console.log('Processed CSV data:', this.csvData);
        },

        handleFileUpload(event) {
            // Access the selected files from the input
            this.selectedFiles = event.target.files;
        },

        uploadCSV() {
            const reader = new FileReader();
            reader.onload = event => {
                const csvData = [];
                const lines = event.target.result.split('\n');
                const headers = lines[0].split(',');

                for (let i = 1; i < lines.length; i++) {
                    const data = lines[i].split(',');
                    if (data.length === headers.length) {
                        const obj = {};
                        for (let j = 0; j < headers.length; j++) {
                            obj[headers[j].trim()] = data[j].trim();
                        }
                        csvData.push(obj);
                    }
                }

                this.csvData = csvData;
            };
            reader.readAsText(file);
        },

        async uploadFiles() {
            this.isButtonClickable = false;
            this.buttonText = "Uploading Please Wait..."
            this.consoleMessages = [];
            if (!this.selectedFiles || this.selectedFiles.length === 0) {
                alert('Please select files to upload.');
                return;
            }

            const uploadPromises = [];

            // Loop through each video file in the selected files list
            for (let i = 0; i < this.selectedFiles.length; i++) {
                // var formData;
                let fileNameWithExtension = this.selectedFiles[i]["name"];

                // Remove the ".mp4" extension
                let fileNameWithoutExtension = fileNameWithExtension.replace(".mp4", "");

                // Gets the video's data
                const videoData = this.csvData.find(videoData => videoData.video_id === fileNameWithoutExtension);

                // Means the Video Data cannot be found in the csv file
                if (!videoData) {
                    console.log("Ignore the Video");
                }

                // can extract the video data and push to server
                else {
                    const apiClient = new ApiService();
                    const user = await apiClient.getUserIDByUsername(videoData["username"]);
                    if (!user) {
                        console.log("User not found")
                        this.logToFailure(this.selectedFiles[i]["name"], "Username doesn't exist");
                    }

                    // Means User Exist
                    else {
                        const uploadHelper = new UploadHelper();

                        // Creates the Form Data
                        const formData = uploadHelper.constructFormData(this.selectedFiles[i], videoData, user["id"]);

                        const uploadPromise = axios.post('https://jojovideos.com/v3/api/postVideo', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Api-Key': sessionStorage.getItem("authKey"),
                                'Access-Control-Allow-Origin': "*"
                            },
                            // onUploadProgress: progressEvent => {
                            //     // Calculate progress percentage and update the progress bar for the current video
                            //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            //     // this.$set(this.progressBars, i, percentCompleted);
                            //     console.log(i + percentCompleted + " completed")
                            // },
                        });



                        uploadPromises.push(uploadPromise);

                    }

                }

            }

            // Iterate and print done for every promise
            for (let i = 0; i < uploadPromises.length; i++) {
                uploadPromises[i].then(
                    (value) => {
                        this.logToConsole(value.data["old_video_id"], 'success');
                        // Update the Table
                        console.log("Old Video ID is ==> " + value.data["old_video_id"]);
                        console.log(value); // Success!
                    },
                    (reason) => {
                        this.logToFailure(value.data["old_video_id"], JSON.stringify(reason));
                        // this.logToConsole('error', 'Error uploading file: ' + JSON.stringify(reason));
                        console.error(reason); // Error!
                    },
                );
            }


            // Use Promise.all() to handle multiple file uploads simultaneously
            Promise.all(uploadPromises)
                .then(responses => {
                    // Handle responses here
                    console.log(responses);
                    var alertMes = "Total Videos Uploaded: " + responses.length + "\n";
                    for (let i = 0; i < responses.length; i++) {
                        alertMes += responses[i].data["msg"]["Video"]["video"] + "\n";
                    }
                    // alert("Uploads Completed!");

                    // or via CommonJS
                    const Swal = require('sweetalert2')

                    if (this.failureUploads.length != 0) {
                        Swal.fire({
                            title: "Info",
                            text: "Bulk uploads have been completed successfully. However, few videos encountered issues. Please refer to the Failure Uploads Table",
                            icon: "warning",
                            confirmButtonColor: "#3085d6",
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Info",
                            text: "Bulk uploads have been completed successfully.",
                            icon: "success",
                            confirmButtonColor: "#3085d6",
                        });
                    }



                    this.isButtonClickable = true;
                    this.buttonText = "Upload Bulk Files";
                })
                .catch(error => {
                    // Handle errors here
                    console.error(error);
                    // alert('Error uploading files.');

                    // or via CommonJS
                    const Swal = require('sweetalert2')
                    Swal.fire({
                        title: "Info",
                        text: "Something Went Wrong!",
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                    });
                    this.isButtonClickable = true;
                    this.buttonText = "Upload Bulk Files";
                });
        },



        logToConsole(video_id, status) {
            // Function to log messages to the console and store them in the component's data
            //console[type](content);
            this.consoleMessages.push({ status, video_id });
        },

        logToFailure(video_id, status) {
            // Function to log messages to the console and store them in the component's data
            //console[type](content);
            this.failureUploads.push({ status, video_id });
        },


    },
    data() {
        return {
            isButtonClickable: true,
            buttonText: "Upload Bulk Files",
            csvData: [],
            consoleMessages: [],
            failureUploads: [],
            selectedFiles: null,
        };
    },
};
</script>
  
  