<template>
  <div class="home">
    <div v-if="isAuthenticated">
      <NavigationBar></NavigationBar>
      <BulkFileUpload></BulkFileUpload>
    </div>
    <div v-else>
      <!-- Redirect to the login page -->
      <div class="unauthorized">
        <div class="unauthorized-content">
          <h1>401 Unauthorized Access</h1>
          <p>Please log in to access this page.</p>
          <router-link to="/">
            <button class=" btn btn-primary login-button">Log In</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import BulkFileUpload from '@/components/BulkFileUpload.vue';
import NavigationBar from '@/components/NavigationBar.vue'


export default {
  data() {
    return {
      isAuthenticated: false,
    };
  },
  mounted() {
    // Check if the authentication key is present in sessionStorage
    const authKey = sessionStorage.getItem('authKey');
    if (authKey) {
      // Set isAuthenticated to true if the key is present
      this.isAuthenticated = true;
    }
  },
  name: 'HomeView',
  components: {
    HelloWorld,
    BulkFileUpload,
    NavigationBar
  }
}
</script>
