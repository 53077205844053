<template>
  <div id="app">
    <div v-if="isAuthenticated">
      <!-- <NavigationBar></NavigationBar> -->
    </div>
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  border-radius: 50%;
}
</style>

<script>
import NavigationBar from './components/NavigationBar.vue'

export default {
  data() {
    return {
      isAuthenticated: false,
    };
  },
  methods: {

  },
  mounted() {
    // Check if the authentication key is present in sessionStorage
    const authKey = sessionStorage.getItem('authKey');
    if (authKey) {
      // Set isAuthenticated to true if the key is present
      this.isAuthenticated = true;
    }
  },
  name: 'AppVue',
  components: {
    NavigationBar
  },
}
</script>

