<template>
    <div class="login-container">
        <div class="login-box">
            <!-- Your logo here -->
            <img src="@/assets/applogo.png" alt="Logo" class="logo" />

            <form @submit.prevent="login">
                <h2>Jo Jo Videos</h2>
                <div class="form-group">
                    <label for="username">Email:</label>
                    <input type="text" id="username" v-model="username" class="form-control" required />
                </div>
                <div class="form-group">
                    <label for="password">Password:</label>
                    <input type="password" id="password" v-model="password" class="form-control" required />
                </div>
                <button type="submit" class="btn btn-primary btn-full-width">Login</button>
            </form>
        </div>
    </div>
</template>

<script>
import ApiService from '../utils/ApiService.js';

export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    methods: {
        async login() {
            // Add your login logic here
            const apiClient = new ApiService();
            const response = await apiClient.adminLogin(this.username, this.password);
            console.log(response);
            const Swal = require('sweetalert2')
            if (response["code"] == 200) {
                sessionStorage.setItem("authKey", response["data"]);
                this.$router.push('/upload-bulk');
            } else if (response["code"] == 401) {
                // Show Invalid Credentials
                Swal.fire({
                    title: "Info",
                    text: "Incorrect Email or Password!",
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                });

            } else {
                // Show Something went wrong
                Swal.fire({
                    title: "Info",
                    text: "Something Went Wrong!",
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                });
            }
        }
    }
};
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-box {
    max-width: 500px;
    width: 80%;
    margin: auto;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.logo {
    max-width: 100px;
    /* Adjust the size of the logo as needed */
    border-radius: 50%;
    /* Make the logo round */
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.btn-full-width {
    width: 100%;
}
</style>
