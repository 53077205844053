<template>
  <div>
    <input type="file" ref="fileInput" multiple @change="handleFileUpload">
    <button @click="uploadFiles">Upload</button>
  </div>


  <div>
    <input type="file" @change="handleCSVUpload" accept=".csv" />
    <button @click="processCSV">Process CSV</button>
    <div v-if="csvData.length > 0">
      <table>
        <thead>
          <tr>
            <th v-for="(header, index) in csvData[0]" :key="index">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in csvData" :key="rowIndex">
            <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    handleCSVUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.readCSV(file);
      }
    },

    readCSV(file) {
      const reader = new FileReader();
      reader.onload = event => {
        const csvData = [];
        const lines = event.target.result.split('\n');
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
          const data = lines[i].split(',');
          if (data.length === headers.length) {
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
              obj[headers[j].trim()] = data[j].trim();
            }
            csvData.push(obj);
          }
        }

        this.csvData = csvData;
      };
      reader.readAsText(file);
    },

    processCSV() {
      // Perform any further processing or actions with the parsed CSV data
      console.log('Processed CSV data:', this.csvData);
    },

    handleFileUpload(event) {
      // Access the selected files from the input
      this.selectedFiles = event.target.files;
    },
    uploadFiles() {
      if (!this.selectedFiles || this.selectedFiles.length === 0) {
        alert('Please select files to upload.');
        return;
      }

      const uploadPromises = [];

      // Loop through each file in the selected files list
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append('video', this.selectedFiles[i]);
        formData.append('user_id', 10);
        formData.append('hashtags_json', [{ "name": "test" }]);
        formData.append('video_id', 0);
        formData.append('privacy_type', 'Public');
        formData.append('sound_id', 30);
        formData.append('allow_duet', 1);
        formData.append('description', 'Bulk index' + i + ' #test');
        formData.append('allow_comments', true);
        formData.append('users_json', []);
        formData.append('videoType', 0);
        formData.append('story', 0);

        // Make a POST request for each file
        const uploadPromise = axios.post('http://localhost:8080/v4/api/bulkUploadNew', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Api-Key': '186c8645-4658-1594-2202-91580',
            'Access-Control-Allow-Origin': "*"
          }
        });

        // const uploadPromise = axios.post('/api/bulkUploadNew', formData, {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //     'X-API-Key': '186c8645-4658-1594-2202-91580',
        //     'Access-Control-Allow-Origin': "*"
        //   }
        // });

        uploadPromises.push(uploadPromise);
      }

      // Use Promise.all() to handle multiple file uploads simultaneously
      Promise.all(uploadPromises)
        .then(responses => {
          // Handle responses here
          console.log(responses);
          alert('Files uploaded successfully!');
        })
        .catch(error => {
          // Handle errors here
          console.error(error);
          alert('Error uploading files.');
        });
    }
  },
  data() {
    return {
      csvData: [],
      selectedFiles: null
    };
  }
};
</script>










<!-- <template>
  <div>
    <input type="file" ref="fileInput" @change="handleFileChange" multiple />
    <button @click="uploadFiles">Upload</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    handleFileChange() {
      // Handle file selection logic here if needed
    },
    async uploadFiles() {
      const files = this.$refs.fileInput.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('video[]', files[i]);
      }

      try {
        const response = await axios.post('http://localhost:8080/v4/api/bulkUploadTest', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': '186c8645-4658-1594-2202-91580',
            'Access-Control-Allow-Origin': "*"
          },
          onUploadProgress: progressEvent => {
            // Calculate progress percentage and log it to the console
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Upload Progress: ${percentCompleted}%`);
          },
        });

        // Handle success response
        console.log('Upload Complete:', response.data);
      } catch (error) {
        // Handle error
        console.error('Upload Error:', error);
      }
    },
  },
};
</script> -->
